body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: white;
}

.parallax-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parallax-section {
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  width: 80vw;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.update-content {
  width: 80vw;
}

.app-download-container {
  background: linear-gradient(45deg, #4caf50, #8bc34a);
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.download-button {
  height: 80px;
  width: 180px;
  font-size: 1.2em;
  border-radius: 10px;
  border-color: white;
  background-color: #ed7671;
  color: #ffffff;
  font-weight: bold;
}

.download-button:hover {
  background-color: #ff4e48;
}

.alert {
  background: linear-gradient(45deg, #e91e63, #9c27b0);
  color: #ffffff;
  padding: 10px;
  border: 1px solid #e57373;
  margin: 20px 0;
}

.footer {
  background-image: linear-gradient(
    to right,
    #c66662,
    #d06a66,
    #d96e69,
    #e3726d,
    #ed7671
  );
  color: #ffffff; /* White text color */
  justify-content: center;
  padding: 20px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.app-icon {
  width: 250px;
}
